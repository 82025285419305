import React from 'react';
import './LatestStory.css';
import firstMeeting from '../../src/WhatsApp Image 2020-01-31 at 7.35.54 PM.jpeg'



function LatestStory() {
    return (
        <div className="container pb-5 align-items-center ">
            <div className="vHeading mt-5">
                <h2>Our Latest Story</h2>
                <p>Open lesser winged midst wherein may morning</p>
            </div>
            <div className="row no-gutter">
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-2 align-items-center">
                    <div className="card align-items-center">
                        <img className="card-img-top " src={firstMeeting} alt="Card image cap" />
                        <div className="card-body">
                            <div className="socialLogo d-flex">
                                <div className="lsCalendar"><i className="fa fa-calendar" aria-hidden="true"></i><span className="pl-2 pr-3">19 Jan 2020</span>|</div>
                                <div className="lsCalendar"><i className="fa fa-book" aria-hidden="true"></i><span className="pl-1" > BGF</span></div>
                            </div>
                            <h5 className="mt-3 mb-2">First meeting</h5>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-2">
                    <div className="card align-items-center">
                        <img className="card-img-top " src={"https://vastukripa.com/blog/wp-content/uploads/2017/08/importance-of-lighting-lamp-before-religious-ceremony-281x300.jpg"} alt="Card image cap" />
                        <div className="card-body">
                            <div className="socialLogo d-flex">
                                <div className="lsCalendar"><i className="fa fa-calendar" aria-hidden="true"></i><span className="pl-2 pr-3">08 feb 2020</span>|</div>
                                <div className="lsCalendar"><i className="fa fa-book" aria-hidden="true"></i><span className="pl-1" > BGF</span></div>
                            </div>
                            <h5 className="mt-3 mb-2">Inauguration ceremony</h5>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 mt-2">
                    <div className="card align-items-center">
                        <img className="card-img-top " src={"https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTbK7uSPICvbp6MvkCSj2oFUILJ4fpxNN2oR3RCeJXYPZFSHaes"} alt="Card image cap" />
                        <div className="card-body">
                            <div className="socialLogo d-flex">
                                <div className="lsCalendar"><i className="fa fa-calendar" aria-hidden="true"></i><span className="pl-2 pr-3">Will update soon..</span>|</div>
                                <div className="lsCalendar"><i className="fa fa-book" aria-hidden="true"></i><span className="pl-1" > BGF</span></div>
                            </div>
                            <h5 className="mt-3 mb-2">Next trust activity</h5>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default LatestStory;
