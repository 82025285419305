import React, {Component} from 'react';
import './FirstPage.css'

class FirstPage extends Component {
    render() {
        return (
            <div  className="firstScreenCon container d-flex flex-column justify-content-center align-items-center">
                <h2 className="compText1">GIVE A HAND</h2>
                <h1 className="compText2">TO MAKE THE BETTER WORLD</h1>
                <p className="compText3">That don't lights. Blessed land spirit creature divide our made two itself upon you'll dominion waters man second good you they're divided upon winged were replenish night</p>
                <div className="compText">
                    <button href="#" className="primary_btn mr-3 ">Donate Now</button>
                    <button className="primary_btn yellow_btn text-white" href="#">See Causes</button>
                </div>
            </div>
        );
    }
}

export default FirstPage;
