import React, {Component} from 'react';
import './Footer.css'

class Footer extends Component {
    render() {
        return (
            <div className="footer-area footerMain">
                <div className="container">
                    <div className="row section_gap">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="single-footer-widget tp_widgets">
                                <h4 className="footer_title large_title">Our Mission</h4>
                                <p style={{textAlign:'left'}}>
                                    &#8226; Farmer’s Welfare & Development<br/>
                                    &#8226; Creating awareness towards organic farming<br/>
                                    &#8226;Afforestation<br/>
                                    &#8226;Women Development & Empowerment in rural areas<br/>
                                    &#8226;Swacch India Mission<br/>
                                    &#8226;Health care & Checkup Facility<br/>
                                    &#8226;Development of village & Rural Sanitation<br/>
                                    &#8226;Education for poor and needy<br/>
                                    &#8226;	Providing Shelter to elderly people<br/>
                                    &#8226;Environmental protection<br/>
                                </p>

                            </div>
                        </div>
                        <div className="offset-lg-1 col-lg-2 col-md-6 col-sm-6">
                            <div className="single-footer-widget tp_widgets">
                                <h4 className="footer_title">Quick Links</h4>
                                <ul className="list">
                                    <li><a href="#">Home</a></li>
                                    <li><a href="#">About</a></li>
                                    <li><a href="#">Causes</a></li>
                                    <li><a href="#">Event</a></li>
                                    <li><a href="#">News</a></li>
                                    <li><a href="#">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6">
                            <div className="single-footer-widget instafeed">
                                <h4 className="footer_title">Gallery</h4>
                                <ul className="list instafeed d-flex flex-wrap">
                                    <li><img src="" alt=""></img></li>
                                    <li><img src="" alt=""></img></li>
                                    <li><img src="" alt=""></img></li>
                                    <li><img src="" alt=""></img></li>
                                    <li><img src="" alt=""></img></li>
                                    <li><img src="" alt=""></img></li>
                                </ul>
                            </div>
                        </div>
                        <div className="offset-lg-1 col-lg-3 col-md-6 col-sm-6">
                            <div className="single-footer-widget tp_widgets">
                                <h4 className="footer_title">Contact Us</h4>
                                <div className="ml-40 ">
                                    <div className='d-flex flex-column'>
                                    <h5 className='contactUsHeading'>
                                        Phone :
                                    </h5>
                                    <h5 className='contactUsDetails'>   +91 9606674408</h5>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <h5 className='contactUsHeading'>
                                            Address :
                                        </h5>
                                        <h5 className='contactUsDetails'>  529,3rd block,nagarabhavi 2nd stage
                                            Bengaluru, KA 560072</h5>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <h5 className='contactUsHeading'>
                                            Email :
                                        </h5>
                                        <h5 className='contactUsDetails'>  info@banyan.org.in</h5>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row d-flex">
                            <p className="col-lg-12 footer-text text-center">

                                Copyright ©

                                2020 All rights reserved | BANYAN GLOBAL FOUNDATION
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Footer;


