import React, {Component} from 'react';
import './BVolunteer.css'

class BVolunteer extends Component {
    render() {
        return (
            <div className="cta-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <h1>Become a volunteer</h1>
                            <p>So seed seed green that winged cattle in. Gathering thing made fly you're<br/>
                                divided deep leave on the medicene moved us land years living.</p>
                            <button href="#" className="primary_btn yellow_btn rounded">join with us</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BVolunteer;
