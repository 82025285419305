import React from 'react';
import './Volunteer.css';
import demoImg from '../demoVolunteer.jpg';

function Volunteer() {
    return (
        <div className="volunteer">
            <div className="vHeading">
                <h2>Meet Our Volunteer</h2>
                <p>Creepeth called face upon face yielding midst is after moveth </p>
            </div>
            <div className="row no-gutters mx-5 ">
                <div className="col-12 col-sm-12 col-md-6 col-lg-3 mb-0 mb-sm-3 mb-md-3 mb-lg-0 d-flex justify-content-center">
                    <div className="card" style={{ width: '18rem',marginTop:20 }}>
                        <img className="card-img-top" src={demoImg} alt="Card image cap" />
                        <div className="card-body">
                            <h4>Arjun N</h4>
                            <p>President</p>
                            <p className="card-text mt-3">So seed seed green that winged cattle in kath moved us land years living.</p>
                            <div className="socialLogo mt-4 d-flex justify-content-center">
                                <a className="iconStyle"><i className="fa fa-facebook iconColor"></i></a>
                                <a className="iconStyle iconActive"><i className="fa fa-twitter iconColor"></i></a>
                                <a className="iconStyle"><i className="fa fa-instagram iconColor"></i></a>
                                <a className="iconStyle"><i className="fa fa-envelope-o iconColor"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-3 mb-0 mb-sm-3 mb-md-3 mb-lg-0 d-flex justify-content-center">
                    <div className="card" style={{ width: '18rem',marginTop:20 }}>
                        <img className="card-img-top" src={demoImg} alt="Card image cap" />
                        <div className="card-body">
                            <h4>Dr Arun kumar</h4>
                            <p>Vice president</p>
                            <p className="card-text mt-3">So seed seed green that winged cattle in kath moved us land years living.</p>
                            <div className="socialLogo mt-4 d-flex justify-content-center">
                                <a className="iconStyle"><i className="fa fa-facebook iconColor"></i></a>
                                <a className="iconStyle iconActive"><i className="fa fa-twitter iconColor"></i></a>
                                <a className="iconStyle "><i className="fa fa-instagram iconColor"></i></a>
                                <a className="iconStyle"><i className="fa fa-envelope-o iconColor"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-3 mb-0 mb-sm-3 mb-md-3 mb-lg-0 d-flex justify-content-center">
                    <div className="card" style={{ width: '18rem',marginTop:20 }}>
                        <img className="card-img-top" src={demoImg} alt="Card image cap" />
                        <div className="card-body">
                            <h4>R Manjunath</h4>
                            <p>Resource Manager</p>
                            <p className="card-text mt-3">So seed seed green that winged cattle in kath moved us land years living.</p>
                            <div className="socialLogo mt-4 d-flex justify-content-center">
                                <a className="iconStyle"><i className="fa fa-facebook iconColor"></i></a>
                                <a className="iconStyle iconActive"><i className="fa fa-twitter iconColor"></i></a>
                                <a className="iconStyle "><i className="fa fa-instagram iconColor"></i></a>
                                <a className="iconStyle"><i className="fa fa-envelope-o iconColor"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-3 mb-0 mb-sm-3 mb-md-3 mb-lg-0 d-flex justify-content-center">
                    <div className="card" style={{ width: '18rem',marginTop:20 }}>
                        <img className="card-img-top" src={demoImg} alt="Card image cap" />
                        <div className="card-body">
                            <h4>Umesh J</h4>
                            <p>Joint secretory</p>
                            <p className="card-text mt-3">So seed seed green that winged cattle in kath moved us land years living.</p>
                            <div className="socialLogo mt-4 d-flex justify-content-center">
                                <a className="iconStyle "><i className="fa fa-facebook iconColor"></i></a>
                                <a className="iconStyle iconActive"><i className="fa fa-twitter iconColor"></i></a>
                                <a className="iconStyle"><i className="fa fa-instagram iconColor"></i></a>
                                <a className="iconStyle"><i className="fa fa-envelope-o iconColor"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Volunteer;
