import React, {Component} from 'react';
import logo from './BanyantreeLogo.png'


class ImageComp extends Component {
    render() {
        return (
            <div>
                <img  style={{width:"70vw",height:"100vh"}} src={logo}></img>
            </div>
        );
    }
}

export default ImageComp;
