import React, { Component } from 'react';
 import "./Header.css";
 import logo from '../../BanyantreeLogo.png'
class Header extends Component {
    render() {
        return (
            <div >
                <nav className="navbar navbar-expand-lg  background">
                    <div className="container">
                        <a className="navbar-brand headerLogo" href="#"><img className='logo' src={logo}/></a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"><i className="fa fa-bars" style={{color:'#fff', fontSize:"28px"}}></i></span>
                    </button>

                    <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
                        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                            <li className="nav-item mr-0 mr-lg-4">
                                <a className="nav-link links active" href="#">HOME <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item mr-0 mr-lg-4">
                                <a className="nav-link links" href="#">ABOUT</a>
                            </li>
                            <li className="nav-item mr-0 mr-lg-4">
                                <a className="nav-link links" href="#">CAUSES</a>
                            </li>
                            <li className="nav-item mr-0 mr-lg-4">
                                <a className="nav-link links" href="#">PAGES</a>
                            </li>
                            <li className="nav-item mr-0 mr-lg-4">
                                <a className="nav-link links" href="#">BLOG</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link links " href="#">CONTACT</a>
                            </li>
                        </ul>
                        {/* <form className="form-inline my-2 my-lg-0">
                            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                        </form>  */}
                    </div>
                    </div>
                </nav>

            </div>
        );
    }
}

export default Header;
