import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from "./Components/Header/Header";
import Volunteer from "./Components/Volunteer";
import FirstPage from "./Components/FirstPage";
import BVolunteer from "./Components/BVolunteer";
import Footer from "./Components/Footer";
import WeareNon from "./Components/WeareNon";
import LatestStory from "./Components/LatestStory";
import ImageComp from "./ImageComp";

function App() {
    return (
        <div className="App">
            <div className='top'>
                <Header/>
                <FirstPage/>
            </div>
            <ImageComp/>
            <WeareNon/>
            <Volunteer/>
            <BVolunteer/>
            <LatestStory/>
            <Footer/>
        </div>
    );
}

export default App;
