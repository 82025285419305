import React, {Component} from 'react';
import './WeareNon.css'
import imgs from '../about-img.webp'

class WeareNon extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="single_about row">
                        <div className="col-lg-6 col-md-12 align-items-center text-center about_left">
                            <img className='image' src={imgs}></img>
                        </div>
                        <div className="col-lg-6 col-md-12 about_right">
                            <h2>
                                WE ARE NON PROFIT TEAM<br />
                            </h2>
                            <p>
                                Banyan Global foundation is a registered trust under the Government of India act to support various needs of the society.
                            </p>
                            <h2 style={{textAlign:"center"}}>The main agenda of the trust is:</h2>
                            <ul  style={{textAlign:"left",marginLeft:30}}>
                                <li>Farmer’s Welfare & Development</li>
                                <li>Creating awareness towards organic farming</li>
                                <li>Afforestation</li>
                                <li>Women Development & Empowerment in rural areas</li>
                                <li>Swacch India Mission</li>
                                <li>Health care & Checkup Facility</li>
                                <li>Development of village & Rural Sanitation</li>
                                <li>Education for poor and needy</li>
                                <li>Environmental protection</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default WeareNon;
